<template>
  <section v-if="'success' === status && videos?.length > 0" class="home-video">
    <h2 class="home-video__title">{{ _T("@New video on the channel") }}</h2>
    <div class="home-video__container">
      <div v-for="video in videos" :key="video.id" class="home-video__item">
        <iframe
          class="home-video__movie"
          width="460"
          height="315"
          :src="video.link"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          loading="lazy"
        />
        <div class="home-video__content">
          <img
            class="home-video__svg"
            src="~/assets/icons/video-view-icon.svg"
            alt="icon"
          />
          <h4 class="home-video__subtitle">
            {{ getLocalizedName(video.title) }}
          </h4>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

const { data: videos, status } = await useAsyncPrivateAPI("/entries", {
  params: {
    content_type: useRuntimeConfig().public.contentful.contentType.videos,
  },
});
</script>

<style lang="scss" scoped>
.home-video {
  padding: 0 7px;
  margin-bottom: 32px;

  @include mobile {
    margin-bottom: 24px;
  }

  &__title {
    text-align: center;
    @include font(28, 39, 600);
    color: black;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 28, 600);
    }
  }

  &__container {
    @extend %width-main;
    margin: auto;

    @include flex-container(row, center);

    @include bigMobile {
      display: block;
    }
  }

  &__item {
    flex: 0 0 33.33%;
    width: 468px;

    padding: 0 9px;

    @include bigMobile {
      width: auto;

      margin: auto auto 40px;
    }

    @include mobile {
      margin-bottom: 16px;
    }
  }

  &__movie {
    width: 100%;
    max-height: 255px;

    background-color: grey;
    border-radius: 16px;

    @include mobile {
      max-height: 180px;
    }
  }

  &__content {
    @include flex-container(row, flex-start);
    gap: 8px;

    padding: 16px 8px 16px 0;

    @include bigMobile {
      padding: 5px 8px 0 0;
    }
  }

  &__svg {
    max-width: 44px;
    width: 100%;
    height: 44px;
  }

  &__subtitle {
    @include font(16, 22, 600);

    @include bigMobile {
      @include font(14, 20, 600);
    }
  }
}
</style>
