<template>
  <section class="contacts">
    <div class="contacts__wrapper">
      <form action="" class="contacts__form">
        <InputText
          v-model="form.firstName"
          class="contacts__input"
          :validate="v.firstName"
          :maxlength="255"
          :caption="_T('@Name')"
        />
        <InputPhone
          v-model="form.phone"
          class="contacts__input"
          :validate="v.phone"
          :caption="_T('@Phone number')"
        />
        <InputEmail
          v-model="form.email"
          class="contacts__input"
          :validate="v.email"
          caption="E-mail"
        />

        <div class="contacts__checkbox-wrp">
          <BaseCheckbox
            v-model="form.isAgreementAccepted"
            :validate="v.isAgreementAccepted"
          >
            <span>
              {{ _T("@I agree with") }}
              <MyLink name="delivery" target="_blank">
                {{ _T("@terms of delivery") }}
              </MyLink>
              {{ _T("@and") }}
              <MyLink name="privacy-policy" target="_blank">
                {{ _T("@privacy policy") }}
              </MyLink>
            </span>
          </BaseCheckbox>
          <ButtonSecondary @click.prevent="subscribe">
            {{ _T("@Send") }}
          </ButtonSecondary>
        </div>
      </form>
    </div>
  </section>
</template>

<script setup>
import InputEmail from "~/modules/shared/inputs/InputEmail.vue";
import InputPhone from "~/modules/shared/inputs/InputPhone.vue";
import InputText from "~/modules/shared/inputs/InputText.vue";
import {
  successfulModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { formatPhone } from "~/utils/phoneHelper";
import {
  emailValidate,
  maxLengthValidate,
  phoneValidate,
  requiredValidate,
  sameAsValidate,
} from "~/utils/validators";
import useVuelidate from "@vuelidate/core";
import { feedbackFormStore } from "./feedbackFormStore";
import { useAPI } from "~/uses/useMyFetch";
import BaseCheckbox from "~/modules/shared/inputs/BaseCheckbox.vue";
import ButtonSecondary from "~/modules/shared/buttons/ButtonSecondary.vue";

const modalStore = useModalStore();
const formStore = feedbackFormStore();

const successfulData = {
  title: _T("@Newsletter subscription"),
  description: _T("@Thank you, our managers will contact you"),
  isWithTimeout: true,
};

const form = reactive({
  firstName: "",
  email: "",
  phone: "",
  isAgreementAccepted: false,
});

const rules = computed(() => ({
  firstName: {
    required: requiredValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 255),
  },
  email: {
    required: requiredValidate(),
    email: emailValidate(),
    maxLength: maxLengthValidate("@Maximum field length", 254),
  },
  phone: {
    required: requiredValidate(),
    validatePhone: phoneValidate(),
  },
  isAgreementAccepted: {
    sameAs: sameAsValidate("@You must accept the terms and conditions", true),
  },
}));

const v = useVuelidate(rules, form);

function subscribe() {
  if (formStore.isFormsReady()) {
    useAPI("/marketing/event", {
      method: "POST",
      body: {
        event: "cooperationRequestInitiated",
        email: form.email,
        phone: formatPhone(form.phone),
        isConfirmed: true,
        name: form.firstName,
      },
    })
      .then(() => {
        modalStore.toggleModal(successfulModal, successfulData);
        formStore.resetForms();
      })
      .catch(() => {});
  }
}

onMounted(() => {
  formStore.addForm({ v, id: "subscription" });
});

onUnmounted(() => {
  formStore.resetForms();
});
</script>

<style lang="scss" scoped>
.contacts {
  max-width: 410px;
  width: 100%;

  @include mobile {
    order: 2;
  }
  &.no-shadow {
    .contacts__wrapper {
      box-shadow: none;
    }
  }
  &__wrapper {
    background: linear-gradient(
        45deg,
        rgba(5, 132, 56, 0.6),
        rgba(65, 204, 121, 0.4)
      ),
      linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.5),
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0),
        rgba(224, 224, 224, 0.5)
      );
    box-shadow: 0 1px 25px rgba(0, 0, 0, 0.05);
    border-radius: 12px;
    padding: 16px 24px;
  }

  &__form {
    @include flex-container(column, flex-start);
    gap: 24px;
  }
  &__input {
    width: 100%;
  }

  &__policy-text {
    @include font(12, 16, 400);
    color: var(--color-ink-base);

    & span {
      text-align: left;
    }

    & a {
      color: white;
    }
  }

  &__checkbox-wrp {
    width: min(100%, 363px);
    align-self: center;

    @include flex-container(column, flex-start);
    gap: 24px;
  }
}
</style>
