<template>
  <section class="home-description">
    <div class="home-description__container">
      <h3 class="home-description__title">{{ _T("@Description") }}</h3>
      <div class="home-description__box">
        <div
          class="home-description__visible ui-rich-text"
          :class="{ active: isShown }"
        >
          <h2>
            {{
              _T(
                "@GreenVision - video surveillance with Ukrainian intelligence",
              )
            }}
          </h2>
          <p>
            {{ _T("@If you need to buy video surveillance for your") }}
          </p>
          <p>
            <strong>{{
              _T("@Popular price available to every Ukrainian consumer")
            }}</strong>
          </p>
          <p>
            {{ _T("@Video surveillance for apartments, houses, offices") }}
          </p>
          <h3>
            {{ _T("@Reliable and efficient equipment to provide") }}
          </h3>
          <ol>
            <li>
              <p>
                <strong>{{ _T("@CCTV Cameras") }}</strong>
                {{ _T("@developed by our specialists answer") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{ _T("@CCTV recorder main page") }}</strong>
                {{ _T("@high-tech equipment") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  _T("@Ready-made video surveillance systems for home/office")
                }}</strong>
                {{ _T("@CCTV kit in one box") }}
              </p>
            </li>
            <li>
              <p>
                <strong>{{
                  _T("@Wireless video surveillance for a private home")
                }}</strong>
                {{ _T("@Can be used indoors or outdoors") }}
              </p>
            </li>
          </ol>
          <p>
            {{ _T("@Quality assurance and technical support") }}
          </p>
          <p>
            {{ _T("@On the official GreenVision website, everyone can") }}
          </p>
        </div>
        <button class="go-to" :class="{ isShown }" @click="isShown = !isShown">
          <span>
            {{ isShown ? _T("@collapse") : _T("@expand") }}
          </span>
          <img
            src="~/assets/icons/nav-arrow-down.svg"
            alt="arrow down"
            loading="lazy"
          />
        </button>
      </div>
      <MyLink class="button-primary" name="catalog">
        <img src="~/assets/icons/catalog.svg" :alt="_T('@Goods catalog')" />
        <span>{{ _T("@Goods catalog") }} </span>
      </MyLink>
    </div>
  </section>
</template>

<script setup>
const isShown = ref(false);
</script>

<style lang="scss" scoped>
.button-primary {
  width: min(265px, 100%);
  align-self: center;
}

.home-description {
  padding: 0 16px;

  &__container {
    @extend %width-main;

    @include flex-container(column, flex-start, center);

    margin: auto;
  }

  &__title {
    @include font(28, 39, 600);
    text-align: center;

    margin-bottom: 16px;

    @include bigMobile {
      @include font(26, 34, 600);
    }

    @include mobile {
      @include font(22, 28, 600);
      margin-bottom: 8px;
    }
  }

  &__box {
    border-radius: 24px;
    background-color: white;

    padding: 32px;
    margin-bottom: 16px;

    @include bigMobile {
      border-radius: 8px;
      padding: 16px;
    }
  }

  &__visible {
    height: 225px;

    margin-bottom: 8px;

    overflow: hidden;
    transition: height 0.4s ease-in-out;

    &.active {
      height: auto;
    }

    @include mobile {
      height: 340px;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  &__subtitle {
    @include font(22, 26, 600);
    margin-bottom: 16px;

    @include bigMobile {
      @include font(20, 24, 600);
    }

    @include mobile {
      @include font(16, 22, 600);
    }
  }

  &__text {
    @include font(16, 20, 400);

    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    @include bigMobile {
      @include font(14, 18, 400);
    }

    @include mobile {
      @include font(12, 16, 400);
    }
  }
}
</style>
